<template>
    <basic-header pretitle="aqfer Universal Tag" title="aUT Usage" :compact="true" />
    <div class="container-fluid">
        <div class="row g-3 align-items-center mt-3">
            <div class="col-auto">
                <InputField colField="mb-0" :editable="true" label="Month" :options="monthOptions"
                    type="select" :modelValue="usageMonth" @change="usageMonthChange($event)" />
            </div>
            <div class="col-auto">
                <InputField label="Start Date"  colField="mb-0" :modelValue="startDate" type="calendar"
                    :input-config="dateConfig"  @update:modelValue="startDateChange($event)"/>
            </div>
            <div class="col-auto">
                <InputField label="End Date"  colField="mb-0" :modelValue="endDate" type="calendar"
                    :input-config="dateConfig"  @update:modelValue="endDateChange($event)"/>
            </div>   
            <div class="col-auto">
                <div class="form-group">
                    <label class="form-label">
                        Show all
                    </label>
                    <div class="custom-control form-switch">
                        <input type="checkbox" :class="['form-check-input', { 'bg-secondary': !showAll }]" id="customSwitch1" v-model="showAll">
                    </div>
                </div>
            </div>
        </div>
        <Tabs :tabs="tabItems">
            <template v-slot:daily>
                <div class="card">
                    <div class="card-body chart-view">
                        <div v-if="loading" class="text-center">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <Line v-else :data="dailyChartData" :options="chartOptions" />
                    </div>
                </div>
            </template>
            <template v-slot:aggregate>
                <div class="card">
                    <div class="card-body">
                        <div v-if="loading" class="text-center">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-6" v-if="aggregateData">
                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th>Key</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in aggregateTableData" :key="item.index">
                                            <td>{{ item.label }}</td>
                                            <td>{{ Intl.NumberFormat("en", {notation: "compact", roundingMode:"floor",
                                                maximumFractionDigits:2}).format(item.value) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-6 chart-view">
                                <Bar :data="aggregateChartData" :options="chartOptions" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Tabs>
    </div>
</template>

<script>
import BasicHeader from '../../components/BasicHeader.vue'
import InputField from "../../components/InputField.vue";
import Tabs from "../../components/Tabs.vue";
import { generateLastSixMonthOptions , getFirstDayOfMonth, getLastDayOfMonth} from '../../utils/commonFunction';
import { mapActions, mapState } from "vuex";
import { Bar, Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors)

const DEFAULT_METRICS = ["aUTAtomic","aUTContainer"]

export default {
    components: { Bar, Line, BasicHeader, InputField, Tabs },

    data() {
        return {
            loading: true,
            showAll: false,
            tabItems: [
                {                
                    name: "Daily",
                    id: "id1",
                    slot: "daily"
                },
                {                
                    name: "Aggregate",
                    id: "id2",
                    slot: "aggregate"
                },
            ],
            dateConfig: {
                wrap: true,
                dateFormat: 'Y-m-d'
            },
            chartOptions:{
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    colors: {
                        forceOverride: true
                    }
                }                
            },            
            monthOptions:generateLastSixMonthOptions()
            
        }
    },

    methods: {
        ...mapActions({
            fetchUsageData: "aut/fetchUsageData",
        }),
        async usageMonthChange(month) {
            this.$store.dispatch('aut/setUsageMonth',month);            
            const startDate = getFirstDayOfMonth(month);
            const endDate = getLastDayOfMonth(month);
            this.$store.dispatch('aut/setUsageDataStartDate',startDate);
            this.$store.dispatch('aut/setUsageDataEndDate',endDate);
            await this.getUsageData();
            
        },     

        async startDateChange(newValue) { 
            if(newValue !== this.$store.state.aut.usageDataStartDate ) {
                this.$store.dispatch('aut/setUsageMonth','custom');   
                this.$store.dispatch('aut/setUsageDataStartDate',newValue);
                await this.getUsageData();
            }
            
        },
        async endDateChange(newValue) {
            if(newValue !== this.$store.state.aut.usageDataEndDate ) {
                this.$store.dispatch('aut/setUsageMonth','custom'); 
                this.$store.dispatch('aut/setUsageDataEndDate',newValue)  
                await this.getUsageData();
            }
            
        },

        async getUsageData() {
            this.loading = true
            await this.fetchUsageData();
            this.loading = false
        }
    },  

    computed: {       
        ...mapState({
            startDate: state => state.aut.usageDataStartDate,
            endDate: state => state.aut.usageDataEndDate,
            usageDailyData: state => state.aut.usageData.aut ? state.aut.usageData.aut.daily_data.data : [],
            usageDailyHeaders: state => state.aut.usageData.aut ? state.aut.usageData.aut.daily_data.headers : [],
            aggregateData: state => state.aut.usageData.aut ? state.aut.usageData.aut.aggregate.data : [],
            aggregateHeaders: state => state.aut.usageData.aut ? state.aut.usageData.aut.aggregate.headers : [],
            usageMonth: state  => state.aut.usageMonth,
            
        }),

        dailyChartData() {
            let chartData = {
                labels: [],
                datasets: []
            }
            if (this.usageDailyData) {
                let labels = []
                let datasets = []
                for (const dataPoint of this.usageDailyData) {
                    const label = dataPoint[this.usageDailyHeaders.indexOf('date')];
                    const dataLabel = dataPoint[this.usageDailyHeaders.indexOf('key')];
                    const value = dataPoint[this.usageDailyHeaders.indexOf('value')];

                    if (dataLabel && (this.showAll || DEFAULT_METRICS.some(substring => dataLabel.includes(substring)))) {
                        if (labels.indexOf(label) === -1)
                            labels.push(label)
                        if (!datasets[dataLabel])
                            datasets[dataLabel] = []
                        datasets[dataLabel].push(value)
                    }
                }

                datasets = Object.entries(datasets).map(([key, value]) => {
                    return { label: key, data: value };
                })

                chartData = {
                    labels: labels,
                    datasets: datasets
                }
            }

            return chartData
        },

        aggregateTableData() {
            var tableData = []
            for (const dataPoint of this.aggregateData) {
                var dataLabel = dataPoint[this.aggregateHeaders.indexOf('key')]
                var value = dataPoint[this.aggregateHeaders.indexOf('value')]
                if (dataLabel && (this.showAll || DEFAULT_METRICS.some(substring => dataLabel.includes(substring)))) {
                    tableData.push({
                        label: dataLabel,
                        value: value
                    })
                }
            }
            return tableData
        },

        aggregateChartData() {
            let chartData = {
                labels: [],
                datasets: []
            }
            if (this.aggregateData) {

                let labels = []
                let dataset = []
                for (const dataPoint of this.aggregateData) {
                    var dataLabel = dataPoint[this.aggregateHeaders.indexOf('key')]
                    var value = dataPoint[this.aggregateHeaders.indexOf('value')]
                    if (dataLabel && (this.showAll || DEFAULT_METRICS.some(substring => dataLabel.includes(substring)))) {
                        labels.push(dataLabel)
                        dataset.push(value)
                    }
                }

                chartData = {
                    labels: labels,
                    datasets: [{ label: "Count", data: dataset }]
                }
            }

            return chartData
        },

    },

    async beforeMount() {
        await this.getUsageData();
    },


}
</script>

<style scoped> 
.chart-view{ 
  height: 50vh !important;  
}
</style>