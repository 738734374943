
<template>
    <detail-header
        pretitle="Sandbox"
        :title="sandbox? sandbox.name : '&nbsp;'"
        :editable="true"
        :editMode="editMode"
        @editClick="onEditClick"
        @deleteClick="onDeleteClick"
        @saveClick="onSaveClick"
        @cancelClick="onCancelClick"
        @closeClick="$router.push(`/${$store.state.currentClient.cid}/amdp/sandboxes`)"
    />

    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <div v-if="error" class="alert alert-danger" role="alert">
                            {{error}}
                </div>
                <div v-if="successMessage" class="alert alert-success" role="alert">
                    {{successMessage}}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">                
                <div v-if="sandbox" class="card">
                    <div class="card-body">
                        <form-fields :fields="sandboxFields" v-model="sandbox" />
                    </div>
                </div>
                <div v-if="loading" class="text-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div  class="card">
                    <div class="card-body" v-if="sandbox">
                        <h4>Users List</h4>
                        <div v-if="editMode" class="input-group mb-3" >
                            <input type="text" class="form-control" v-model="inputVal" placeholder="Users Email" aria-label="Users Email" aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <button  @click="onAddSandboxUser" class="btn btn-primary" type="button">Add User</button>
                            </div>
                        </div> 
                        <list-group v-if="userList" :editMode="editMode" :title="'Users List'" :list="userList"  @deleteBtnClick="onDeleteSandboxUser"></list-group>
                    </div>

                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import DetailHeader from "../../components/DetailHeader.vue";
import FormFields from "../../components/FormFields.vue";
import ListGroup from '../../components/ListGroup.vue';
import getSandboxFields from "../../utils/field-constants/sandboxFields"
import { mapActions } from "vuex";
export default {
    name: "SandboxDetail",
    components: { DetailHeader,  FormFields, ListGroup },
    data() {        
        return {
            editMode: false,
            sandbox: {},
            sandboxFields: [],
            snapshot: null,
            error: "",
            successMessage: "",
            userList: [],
            inputVal: "",
            loading: false
        };
    },
    beforeMount() {
        let fields = getSandboxFields();  
        this.sandboxFields = fields.map(item => {item.editable = false; return item;});  
        this.fetchSandbox();
    },
    methods: {
        ...mapActions({
            readSandbox: "sandbox/readSandbox",
            updateSandbox: "sandbox/updateSandbox",
            deleteSandbox: "sandbox/deleteSandbox",
            readSandboxUserList: "sandbox/fetchSandboxUsers",
            addSandboxUser:"sandbox/addSandboxUser",
            deleteSandboxUser: "sandbox/deleteSandboxUser",
            showModal: "showModal",
        }),
        setEditMode(mode) {
            let self = this;
            this.editMode = mode;
            this.successMessage = null;
            this.error = null;
            //Make primary fields non editable.
            this.sandboxFields = this.sandboxFields.map(item => {
                             item.editable = (item.key)?false:self.editMode; 
                             return item;
                        });
        },
        async fetchSandbox() {
            this.loading = true;
            let response={};
          
            try {
                response = await this.readSandbox(this.$route.params.id);
                this.loading = false
            } catch (error) {
                this.loading = false;
                if (error.response && error.response.data && error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
                return;
            }
            
            this.sandbox = await response.data;
            if(!this.sandbox)
                this.error = "Sandbox details not found!"
            this.snapshot = {...this.sandbox}
            this.fetchSandboxUserList();
        },
        async fetchSandboxUserList() {
            let response;
            try {
                response = await this.readSandboxUserList(this.sandbox.name);
                this.userList = response.data;
            } catch (error) {
                if (error.response.data && error.response.data.error)
                    this.error = error.response.data.error;
                else this.error = error.message;
                return;
            }
            
        },

        async onAddSandboxUser() {
            const userEmail =  this.inputVal;
            try {
                const response = await this.addSandboxUser({"sandboxName":this.sandbox.name, "email":userEmail});
                this.fetchSandboxUserList();
                this.successMessage = 'Sandbox User added successfully.'
            } catch (error) {
                if (error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;
            }

        },
        async onDeleteSandboxUser(userEmail) {
            try {
                const response = await this.deleteSandboxUser({"sandboxName":this.sandbox.name, "email":userEmail});
                this.fetchSandboxUserList();
            } catch (error) {
                if (error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;
            }
        },
        onCancelClick() {
            this.setEditMode(false)
            this.sandbox = {...this.snapshot}
            this.error = null
        },
        onEditClick() {
            this.setEditMode(true)
        },
        onDeleteClick() {
            const self = this;
            this.showModal({ 
                component: "confirm", data: {
                    title: 'Delete Sandbox',
                    message: `Are you sure you want to delete ${this.sandbox.name}?`,
                    async confirm() {
                        try {
                            const response = await self.deleteSandbox(self.sandbox.name);
                            self.$router.push(`/${self.$store.state.currentClient.cid}/amdp/sandboxes`)
                            return response;
                        } catch (error) {
                            if (error.response.data.error)
                                self.error = error.response.data.error;
                            else self.error = error.message;
                            return;
                        }
                    },
                } 
            });
        },
        async onSaveClick() {
            try {
                this.sandbox.job_promotions = "allow";
                const response = await this.updateSandbox(this.sandbox);
                this.setEditMode(false);
                this.snapshot = {...this.sandbox}
                this.successMessage = 'Sandbox updated successfully.'
            } catch (error) {
                if (error.response.data.error)
                    self.error = error.response.data.error;
                else self.error = error.message;
            }
            
        }
    },
};
</script>

<style scoped>
.input-group>.input-group-append>.btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
</style>