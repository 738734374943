<template>
    <div class="modal-card card">
        <form @submit.prevent="onAddCredential()">
            <div class="card-header">
                <!-- Title -->
                <h4 class="card-header-title">
                    Add Credential
                </h4>
                <!-- Close -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="card-body">
                <div class="form-group">
                    <label class="form-label">Credential Type <span class="text-danger">&nbsp;*</span></label>
                    <select class="form-select" @change="onSelectCredentialType($event)" v-model="credTypeName">
                        <option v-for="option in this.credentialOptions" :key="option.index" :value="option.value">
                            {{ option.label }}
                        </option>
                    </select>
                </div>
                <FormFields :fields="fields" v-model="credential" />
                <div v-if="error" class="text-danger">
                    {{ error }}
                </div>
            </div>
            <div class="card-footer">
                <div class="text-end">
                    <SubmitButton :fields="fields" v-model="credential" :loading="loading" />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import FormFields from '../FormFields.vue';
import SubmitButton from '../SubmitButton.vue';
import { mapActions } from 'vuex';
import { isDuplicate } from '../../utils/commonFunction';
export default {
    components: { FormFields, SubmitButton },
    data() {
        return {
            error: "",
            credential: {},
            loading: false
        }
    },
    computed: {
        fields() {
            let currentCredentialType = this.credentialType;
            let fields = [{
                name: "name",
                type: "text",
                label: "Credential Name",
                placeholder: "Enter name",
                validation: [{ type: "required" },
                {
                    type: "regex", pattern: "^[a-zA-Z0-9_]+$",
                    errMsg: "Credential name may contain only alphabets or numbers"
                }]
            }]
            if(currentCredentialType){
                currentCredentialType.fields.map((value, index) => {
                    fields.push({
                        name: value.name,
                        type: "text",
                        label: value.name,
                        editable: true,
                        url: value.type == "url" ? "https://onetimesecret.com/" : null,
                        placeholder: value.description,
                        toolTip: value.type == "url" ? "Do not directly enter your secret here. use the onetimesecret url generated using the 'key'" : null,
                        validation: value.required ? [{ type: "required" }] : []
                    })
                    if (value.type == 'url') {
                        fields[index + 1].validation.push({
                            type: "url", base: "https://onetimesecret.com/secret/",
                            errMsg: "Onetimesecret URL is required"
                        })
                    }

                })
            }
            return fields
        },
        credentialType(){
            return this.$store.state.amdp.currentCredentialType
        },
        credentialTypes(){
            return this.$store.state.amdp.credentialTypes
        },
        credentialOptions(){
            let types = this.$store.state.amdp.credentialTypes
            let options = types.map((type) => {
                return {
                    label: type.name,
                    value: type.name,
                }
            })
            return options
        },
        credTypeName: {
            get (){
                return this.credentialType ? this.credentialType.name: ""
            },
            set(value){
                
            }
        }
    },
    name: "AddCredential",
    methods: {
        ...mapActions({
            createCredential: "amdp/createCredential",
            showModal: "showModal"
        }),
        onSelectCredentialType(event) {
            let credtype = this.credentialTypes.find(type => {
                return type.name == event.target.value
            });
            this.$store.dispatch("amdp/setCurrentCredentialType", credtype);
        },
        async onAddCredential() {
            if (!isDuplicate(this.credential.name, this.$store.state.amdp.credentials, "name", null)) {
                this.loading = true;
                let toastoptions;
                try {
                    let credentialPayload = {
                        name: this.credential.name,
                        type: this.credTypeName,
                        fields: this.credentialType.fields.map((field) => {
                            field["value"] = this.credential[field.name]
                            return field
                        })
                    };
                    await this.createCredential(credentialPayload)
                    toastoptions = {
                        message: "Credentials added successfully.",
                        type: "success"
                    }

                } catch (error) {   
                    this.error= error.message
                    toastoptions = {
                        message: "Something went wrong when adding the Credential. Please contact support.",
                        type: "error"
                    }
                    this.$store.dispatch('addToast', toastoptions);
                    return
                }

                this.loading = false;
                this.$store.dispatch('addToast', toastoptions);
                this.$store.dispatch('hideModal')
                this.$store.dispatch('amdp/fetchCredentials');
            } else {
                this.error = "'" + this.credential.name + "' name exits."
            }
        }
    }
};
</script>

<style>

</style>