<template>
    <basic-header pretitle="Administration" title="Usage" :compact="true" />
    <div class="container-fluid">
        <tabs :tabs="tabItems">
            <template v-slot:current>      
                <div v-if="loading" class="text-center mt-5">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <h2 v-else class="mt-4">Current Month Usage ({{startDateLabel}} - {{endDateLabel}})</h2>
                <EntitlementUsageCharts v-if="type=='Current'" :chartValue="entitlementUsage" :type="type"/>
            </template>
            <template v-slot:historical>
                <div v-if="loading" class="text-center mt-5">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <HistoricalEntitlementUsageCharts v-if="type=='Historical'" :monthlyData="historicalEntitlementUsage" :type="type"/>
            </template>
        </tabs>
    </div>
</template>

<script>
import axios from "axios";

import dayjs from "dayjs";
import BasicHeader from "../../components/BasicHeader.vue";
import Tabs from "../../components/Tabs.vue";
import EntitlementUsageCharts from "../../components/admin/EntitlementUsageCharts.vue";
import HistoricalEntitlementUsageCharts from "../../components/admin/HistoricalEntitlementUsageCharts.vue";
export default {
    components: { BasicHeader, Tabs, EntitlementUsageCharts, HistoricalEntitlementUsageCharts },
    data() {
        return {
            loading: false,
            countsData: [],
            entitlements: [],
            entitlementUsage: [],
            historicalEntitlementUsage: [],
            dateConfig: {
                wrap: true,
                dateFormat: 'Y-m-d'
            },
            startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
            endDate: dayjs().endOf('month').format('YYYY-MM-DD'),
            snapType: 'Current',
        }
    },
    watch:{
        startDate(oldVal, newVal){
            if(oldVal!==newVal &&this.type==this.snapType){
                this.getEntitlementUsage(true)
            }
        },
        endDate(oldVal, newVal){
            if(oldVal!==newVal && this.type==this.snapType){
                this.getEntitlementUsage(true)
            }
        },  
    },
    computed: {
        startDateLabel() {
            return dayjs(this.startDate).format("MMM. D")
        },
        endDateLabel() {
            return dayjs(this.endDate).format("MMM. D, YYYY") 
        },
        tabItems() {
            var self= this
            return [
                {
                    name: "Current",
                    id: "id1",
                    slot: "current",
                    onClick: function(){
                        self.getCurrentEntitlementUsage()
                    }
                },
                {
                    name: "Historical",
                    id: "id2",
                    slot: "historical",
                    onClick: function(){
                        self.getHistoricalEntitlementUsage()
                    }
                },
                {
                    name: "aUT",
                    id: "id3",
                    slot: "aut",
                    hide:  true
                },
                {
                    name: "aUT Legacy",
                    id: "id1",
                    slot: "aut-unpooled",
                    hide:  true
                },

            ]

        },
        type(){
            return this.$store.state.activeTab || 'Current'
        }
    },
    methods: {
        async initChartData() {
            await this.getCurrentEntitlementUsage()
        },
        async fetchEntitlementUsage(startDate, endDate) {
            var entitlementUsage = []
            entitlementUsage = await axios.get(
                `${this.$store.state.clientMgmtUrl}/v1/clients/${this.$store.state.currentClient.cid}/product_entitlement/dashboard?start_date=${startDate}&end_date=${endDate}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.accessToken}`,
                    },
                }
            );
            return entitlementUsage.data ? entitlementUsage.data:[]
            
        },
        async getCurrentEntitlementUsage(dateChange=false) {
            this.startDate = dayjs().startOf('month').format('YYYY-MM-DD');
            this.endDate= dayjs().endOf('month').format('YYYY-MM-DD');
            
            this.loading = true;
            this.entitlementUsage = [];
            this.entitlementUsage = await this.fetchEntitlementUsage(this.startDate, this.endDate)

            console.log("Final Usage Info", this.entitlementUsage)
            
            this.loading = false;
            this.snapType= this.type
        },

        async getHistoricalEntitlementUsage(dateChange=false) {
            this.loading = true;
            this.historicalEntitlementUsage = [];
            let historicalEntitlementUsage = [];
            
            const monthSpan = 6
            for (let i=monthSpan-1; i>-1; i--){
                let dateObj = dayjs().subtract(i, "month");
                let startDate = dateObj.startOf('month').format('YYYY-MM-DD');
                let endDate = dateObj.endOf('month').format('YYYY-MM-DD');
                let month = dateObj.format('MMMM');

                historicalEntitlementUsage[month] = await this.fetchEntitlementUsage(startDate, endDate)
            }


            this.historicalEntitlementUsage = historicalEntitlementUsage

            console.log("Final Usage Info", this.historicalEntitlementUsage)
            
            this.loading = false;
            this.snapType= this.type
        },
    },
    async mounted() {
        await this.initChartData();
        this.snapType= this.type;
    }
};
</script>

<style></style>