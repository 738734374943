<template>
	<div  class="container-fluid">
		<nav-header
			:navItems="[
				{
					name: 'Configuration',
					link: {
						name: 'Sandbox Job Configuration',
						params: {
							sandboxId: `${selectedSandbox}`,
							jobId: `${$route.params.jobId}`,
						},
					},
				},
				{
					name: 'Meters',
					link: {
						name: 'Sandbox Job Meters',
						params: {
							sandboxId: `${selectedSandbox}`,
							jobId: `${$route.params.jobId}`,
						},
					},
					disable: !showMetersTab,
				},
				{
					name: 'Override',
					link: {
						name: 'Sandbox Job Override',
						params: {
							sandboxId: `${selectedSandbox}`,
							jobId: `${$route.params.jobId}`,
						},
					},
					disable: !showOverrideTab,
				},	
				{
					name: 'Run History',
					link: {
						name: 'Sandbox Job Run History',
						params: {
							sandboxId: `${selectedSandbox}`,
							jobId: `${$route.params.jobId}`,
						},
					},
				},			
				{
					name: `${$route.params.executionId}`,
					link: {
						name: 'Sandbox Job Execution Details',
						params: {
							sandboxId: `${selectedSandbox}`,
							jobId: `${$route.params.jobId}`,
							executionId: ` `,
						},
					},
					closeTab: true,
					disable: true,
				},
				{
					name: 'Promotions',
					link: {
						name: 'Sandbox Job Promotions',
						params: {
							sandboxId: `${selectedSandbox}`,
							jobId: `${$route.params.jobId}`,
						},
					},
					disable: !hasProdV2Sandbox(),
				}
							
			]"
			pretitle="Job"
			:breadcrumbTitle="true"
			@nav-close-clicked="closeNav"
		>
			<template v-slot:breadcrumb-title>
				<nav aria-label="breadcrumb" class="header-title">
					<ol class="breadcrumb">
						<li class="breadcrumb-item">
							<a @click="closeClick" href="#" class="h1">{{
								selectedSandbox
							}}</a>
						</li>
						<li class="breadcrumb-item active h1" aria-current="page">
							{{ $route.params.jobId }}
						</li>
					</ol>
				</nav>
			</template>
			<template v-slot:header-button-content>
				<div class="custom-btn-group">
				<span v-if="editMode && !hideSaveBtn">
					<button
						@click.prevent="onSaveClick()"
						class="btn btn-success"
						:disabled="isButtonDisabled"
					>
						<i
							v-if="isButtonDisabled"
							class="fa fa-circle-exclamation"
							title="Please fix lint errors"
							data-bs-toggle="tooltip"
							data-bs-placement="left"
						></i>
						Save</button
					>
					<button
						@click.prevent="onSaveAndRunClick()"
						class="btn btn-success"
						:disabled="isButtonDisabled"
					>
						<i
							v-if="isButtonDisabled"
							class="fa fa-circle-exclamation"
							title="Please fix lint errors"
							data-bs-toggle="tooltip"
							data-bs-placement="left"
						></i>
						Save & Run
					</button>
					<button @click.prevent="onDeleteClick()" class="btn btn-danger">
						Delete</button
					>
					<button
						@click.prevent="onCancelClick()"
						class="btn btn-secondary"
					>
						Cancel</button
					>
				</span>
				<span v-else-if="editMode && showOverrideEdit">
					<button
						@click.prevent="setSaveOverride()"
						class="btn btn-success"
						:disabled="isButtonDisabled"
					>
						<i
							v-if="isButtonDisabled"
							class="fa fa-circle-exclamation"
							title="Please fix lint errors"
							data-bs-toggle="tooltip"
							data-bs-placement="left"
						></i>
						Save Override</button
					>
					<button @click.prevent="onDeleteClick()" class="btn btn-danger">
						Delete</button
					>
					<button
						@click.prevent="onCancelClick()"
						class="btn btn-secondary"
					>
						Cancel</button
					>
				</span>	
				<span v-else-if="!editMode && !hideEditBtn">
					<button
						type="button"
						@click.prevent="onEditClick()"
						class="btn btn-primary"
					>
						Edit</button
					>
				</span>				
				<button
					v-if="!editMode && !hideRunJobBtn"
					@click="btnClick"
					class="btn btn-primary"
					:disabled="isButtonDisabled"
				>
					<div
						v-if="runClicked"
						class="spinner-border spinner-border-sm"
						role="status"
					>
						<span class="visually-hidden">Loading...</span>
					</div>
					<div v-else>
						<slot></slot>
						<i
							v-if="isButtonDisabled"
							class="fa fa-circle-exclamation"
							title="Please fix lint errors"
							data-bs-toggle="tooltip"
							data-bs-placement="right"
						></i>
						Run Job
					</div></button> 			
				<button  v-if="!hidePromoteBtn && hasProdV2Sandbox() && isAccessByUserRole(promoteJobRoles) && latestSuccessfulJobRun" @click.prevent="onPromoteClick()" class="btn btn-primary">
					{{ promoteBtnLabel }}</button>
				<button
					v-if="showCancelJobBtn"
					class="btn btn-secondary"
					@click="setCancelJob"
				>
					<div
						v-if="runClicked"
						class="spinner-border spinner-border-sm"
						role="status"
					>
						<span class="visually-hidden">Loading...</span>
					</div>
					<div v-else><slot></slot>Cancel Job</div>
				</button>
				<button @click="closeClick" class="btn btn-secondary lift">
					<i class="fa fa-arrow-left me-1"></i> Back to job list
				</button>
				<ActionDropdown :btnList="btnList"/>
			</div>
			</template>
		</nav-header>

		<div>
			<router-view v-if="job"
				:editMode="editMode"
				:isSandbox="true"
				:job="job"
				:override="override"
				:meters="meters"
				:runJobTrigger="runJobTrigger"
				:saveJobTrigger="saveJobTrigger"
				:saveAndJobTrigger="saveAndJobTrigger"
				:cancelJobTrigger="cancelJobTrigger"
				:copyCurl="copyCurl"
				:snapshot="snapshot"
				:saveJobConfigTrigger ="saveJobConfigTrigger"
				:saveJobOverrideTrigger ="saveJobOverrideTrigger" 
				v-on:setRunClick="setRunClick"
				v-on:onLintFailure="setRunJobBtnState"
				v-on:triggerEditMode="setEditMode"
				v-on:hideEdit="setHideEdit"
				v-on:hideSaveBtn= "setHideSave"
				v-on:hidePromoteBtn="setHidePromoteBtn"
				v-on:hideRunJob="setHideRunJob"
				v-on:showOverrideEdit="setShowOverrideEdit"
				v-on:showCancelJob="setShowCancelJob"
				v-on:setSnapshot ="setSnapshot"
				v-on:setSaveConfigBtn ="setSaveConfigBtn"
			></router-view>
		</div>

	</div>	
</template>

<script>
	import NavHeader from "../../components/NavHeader.vue";
	import { mapState, mapActions } from "vuex";
	import ActionDropdown from "../../components/ActionDropdown.vue";
	export default {
		name: "SandboxJobDetail",
		components: { NavHeader, ActionDropdown},
		data() {
			return {
				runJobTrigger: false,
				saveJobTrigger: false,
				cancelJobTrigger: false,
				saveAndJobTrigger: false,
				job: null,
				override:null,
				meters:null,
				selectedSandbox: this.$route.params.sandboxId,
				snapshot: null,
				error: "",
				successMessage: "",
				runClicked: false,
				isButtonDisabled: false,
				editMode: false,
				cancelJob: false,
				hideSaveBtn: false,
				hideEditBtn: false,
				hideRunJobBtn: false,
				showOverrideEdit: false,
				showCancelJobBtn: false,
				hidePromoteBtn: false,
				showSaveConfigBtn: false,
				saveJobConfigTrigger: false,
				saveJobOverrideTrigger: false,
				sandboxJobs: this.$store.state.sandbox.sandboxJobs,
				navigate: this.$router,
				copyCurl:false,
				promoteJobRoles: [1,2,3,4],
				showOverrideTab: false,
				showMetersTab: false
			};
		},
		computed: {
			...mapState({
				latestSuccessfulJobRun: (state) =>
					state.sandbox.latestSuccessfulJobRun,
			}),
			btnList(){
				return [
					{
						id:1,
						name: 'Clone Job',
						visible: !this.editMode && !this.hideRunJobBtn && this.$route.params.jobId,
						onClick:()=>this.onCloneJob(),
					},
					{
						id:2,
						name: 'cURL Command',
						visible: !this.editMode && !this.hideRunJobBtn && this.$route.params.jobId,
						onClick:()=>this.copyCurlClick()
					},
					{
						id:3,
						name: 'Save as Current Config',
						visible: this.showSaveConfigBtn,
						onClick:()=>this.setSaveJobConfig()
					}]
			},
			promoteBtnLabel() {
				return (this.$route.params.executionId)? "Promote Job Run": "Promote Job";
			}
		},
		beforeMount(){
			this.fetchSandboxJob();			
		},
		methods: {
			...mapActions({
				readSandboxJob: "sandbox/readSandboxJob",
				readOverrideFile: "sandbox/readOverrideFile",
				deleteSandboxJob: "sandbox/deleteSandboxJob",
				showModal: "showModal",
			}),
			async fetchSandboxJob() {
				let self = this;
				let response;
				try {
					response = await this.readSandboxJob(
						this.$route.params.jobId
					);
					this.job = await response.data;
					if(this.job.meters){
						this.meters = this.job.meters
						this.showMetersTab = true;
					}else{
						this.meters = null;
						this.showMetersTab = false;
					}
					this.snapshot = JSON.parse(JSON.stringify(this.job)); // deep clone
					self.$store.dispatch("sandbox/fetchSandboxJobRuns", {jobName: self.job.name, limit:this.$store.state.sandbox.executionsListLimit});
				} catch (error) {
					if (error.response.data.error)
						this.error = error.response.data.error;
					else this.error = error.message;
				} finally {
					this.fetchOverrideFile()
				}
			},
			async fetchOverrideFile() {
				let response;
				try {
					response = await this.readOverrideFile(
						this.$route.params.jobId
					);
					this.override = await response.data;
					if(this.override)						
						this.showOverrideTab= true;
					else						
						this.showOverrideTab= false;

				} catch (error) {
					this.showOverrideTab= false;
					if (error.response.data.error)
						this.error = error.response.data.error;
					else this.error = error.message;
				}

			},
			setEditMode(mode) {
				this.editMode = mode;
				this.successMessage = null;
				this.error = null;
			},
			redirect() {
				this.navigate.push({
					name: "Sandbox Job List",
				});
			},
			onDeleteClick() {
				const self = this;
				this.showModal({
					component: "confirm",
					data: {
						title: "Delete Sandbox",
						message: `Are you sure you want to delete ${this.job.name}?`,
						async confirm() {
							try {
								const response = await self.deleteSandboxJob(
									self.job.name
								);
								self.$store.dispatch("hideModal");
								self.$router.push({
									name: "Sandbox Job List",
									params: {
										sandboxId: `${self.$route.params.sandboxId}`,
									},
								});
								return response;
							} catch (error) {
								if (error.response && error.response.data.error)
									self.error = error.response.data.error;
								else self.error = error.message;
								return;
							}
						},
					},
				});
			},
			onEditClick() {
				this.setEditMode(true);
			},
			onCancelClick() {
				this.job = { ...this.snapshot };
				this.setEditMode(false);
			},
			btnClick() {
				this.runClicked = true;
				this.runJobTrigger = !this.runJobTrigger;
			},
			copyCurlClick(){
				this.copyCurl = !this.copyCurl;
			},
			onSaveClick() {
				this.successMessage = null;
				this.error = null;
				this.saveJobTrigger = !this.saveJobTrigger;
			},
			onSaveAndRunClick() {
				this.saveAndJobTrigger = !this.saveAndJobTrigger;
			},
			setRunJobBtnState(btnState) {
				this.isButtonDisabled = btnState;
			},
			setRunClick(runClick) {
				this.runClicked = runClick;
			},
			onPromoteClick() {
				const dst_job_name = this.$store.state.sandbox.prodV2Sandboxes.length?this.$store.state.sandbox.prodV2Sandboxes[0].name:"";
				let  srcJobRunId =  this.latestSuccessfulJobRun.id;							
				const promoteObject = 	{
					dst_sandbox_name: dst_job_name,
					dst_job_name: this.job.name,
					description: "",
					vars: ""					
				}
				//If promote job is triggered from job run details page
				if(this.$route.params.executionId) {
					srcJobRunId = this.$store.state.sandbox.successfulJobRunList.filter(jobRun => jobRun.execution_id === this.$route.params.executionId)[0].id; 
					promoteObject.src_job_run_id =	srcJobRunId;
				}	
				this.showModal({ component: 'promoteJob', data: {promoteObject:promoteObject }});	
			},
			setCancelJob() {
				this.cancelJobTrigger = !this.cancelJobTrigger;
			},
			setHideEdit(hideEdit) {
				this.hideEditBtn = hideEdit;
			},
			setHideSave(hideSave) {
				this.hideSaveBtn = hideSave;
			},
			setHideRunJob(hideRunJob) {
				this.hideRunJobBtn = hideRunJob;
			},
			setShowOverrideEdit(showOverride) {
				this.showOverrideEdit = showOverride;
			},
			setHidePromoteBtn(hidePromote) {
				this.hidePromoteBtn = hidePromote;
			},
			hasProdV2Sandbox() {
				return this.$store.getters['sandbox/hasProdV2Sandbox']
			},
			setShowCancelJob(showCancelJob) {
				this.showCancelJobBtn = showCancelJob;
			},
			setSnapshot(job) {
				this.snapshot = job;
				this.onCancelClick();
				this.fetchSandboxJob();
			},
			closeClick() {
				this.$router.push({
					name: "Sandbox Job List",
					params: {
						sandboxId: `${this.$route.params.sandboxId}`,
					},
				});
			},
			closeNav(navName) {
				this.$router.push({
					name: "Sandbox Job Run History",
					params: {
						sandboxId: `${this.$route.params.sandboxId}`,
						jobId: `${this.$route.params.jobId}`,
					},
				});
			},
			isAccessByUserRole(roles) {
				return this.$store.getters.userRoles.isAccessByRole(roles);
			},
			setSaveJobConfig(){
				this.saveJobConfigTrigger = !this.saveJobConfigTrigger
			},
			setSaveOverride(){
				this.saveJobOverrideTrigger = !this.saveJobOverrideTrigger
			},
			setSaveConfigBtn(val){
				this.showSaveConfigBtn = val
			},
			onCloneJob(){
				this.showModal({component:"CloneSandboxJob", data: this.job});
			},
		}		
	};
</script>

<style scoped>
.custom-btn-group{
	display: flex;
    align-items: center;
}
.btn{
	margin-right: 12px;
}
</style>
