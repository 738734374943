import router from '../../router'
import axios from 'axios'
import dayjs from "dayjs";

const store = {
    namespaced:true,
    state: {
        autClientData: null,
        autProdClientData: null,
        currentClass: {},
        currentClassData: null,
        deploymentStatus: null,
        countries: null, 
        domainList: [],
        currentEdgeHostNameObj: {},
        tagTemplates: [],
        publicTagTemplates: [],
        initiatorFields: [],
        initiators: [],
        privacySignals:[],
        privacyConfig:[],
        usageData: {},
        usageDataStartDate: dayjs().startOf('month').format("YYYY-MM-DD"),
        usageDataEndDate: dayjs().format("YYYY-MM-DD"),
        usageMonth: dayjs().format('MM')
    },
    mutations: {
        setAutClientData(state, data) {
            state.autClientData = data;
        },
        setAutProdClientData(state, data) {
            state.autProdClientData = data;
        },
        setCurrentClass(state, data) {
            state.currentClass[data.cid] = data.cls;
        },
        setCurrentClassData(state, data) {
            state.currentClassData = data;
        },
        setDeploymentStatus(state, data) {
            state.deploymentStatus = data;
        },
        setCountries(state, data) {
            state.countries = data;
        },
        setDomainList(state, data) {
            state.domainList = data;
        },
        setCurrentEdgeHostNameObj(state, data) {
            state.currentEdgeHostNameObj = data;
        },
        setTagTemplates(state, data) {
            state.tagTemplates = data;
        },
        setPublicTagTemplates(state, data) {
            state.publicTagTemplates = data;
        },
        setInitiatorFields(state, data) {
            state.initiatorFields = data;
        },
        setInitiators(state, data) {
            state.initiators = data;
        },
        setPrivacySignals(state, data) {
            state.privacySignals = data;
        },
        setPrivacyConfig(state, data){
            state.privacyConfig = data
        },
        setUsageData(state, data) {
            state.usageData = data
        },
        setUsageDataStartDate(state, startDate) {
            state.usageDataStartDate = startDate
        },
        setUsageDataEndDate(state, endDate) {
            state.usageDataEndDate = endDate
        },
        setUsageMonth(state, month) {
            state.usageMonth = month
        },
    },

    actions: {
        async fetchClient({rootState, state, commit}) {
            return axios.get(rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}?detail=pixel`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        fetchAutClientData({rootState, state, commit}) {
            axios.get(rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}?detail=pixel`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setAutClientData', response.data);             
                                
            });
        },

        fetchAutProdClientData({rootState, state, commit}) {
            axios.get(rootState.mgmtUrl + `/v1/production/clients/${rootState.currentClient.cid}?detail=pixel`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setAutProdClientData', response.data);   
            }).catch((err) =>{
                commit('setAutProdClientData', null); 
            })
        },

        onClientChange({commit}) {
            commit('setTagTemplates', [])
            commit('setDomainList', [])
        },

        setAutClassData({ commit }, data) {            
            commit('setCurrentClassData', data);
        },
        setDeploymentStatus({ commit }, data) {            
            commit('setDeploymentStatus', data);
        },

        setCurrentClass({ rootState, commit }, cls) {            
            commit('setCurrentClass', {cid:rootState.currentClient.cid, cls});
        },
        setCurrentEdgeHostNameObj( { commit }, data){
            commit('setCurrentEdgeHostNameObj', data);  
        },
        setUsageData({commit}, data) {
            commit('setUsageData', data)
        },
        setUsageDataStartDate({commit}, startDate) {
            commit('setUsageDataStartDate', startDate)
        },
        setUsageDataEndDate({commit}, endDate) {
            commit('setUsageDataEndDate', endDate)
        },
        setUsageMonth({commit}, month) {
            commit('setUsageMonth', month)
        },
        fetchUsageData({rootState, state, commit}) {
            commit('setUsageData', {});
            let handlerUrl = rootState.countsUrl + `/v1/cids/${rootState.currentClient.cid}/products/aut/usage?start_date=${state.usageDataStartDate}&end_date=${state.usageDataEndDate}`
            return axios.get(handlerUrl, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                if (response.data) {
                    commit('setUsageData', response.data);
                }
                return response;
            }).catch((e) => {
                return e;
            })
        },
        fetchAutClassData({rootState, state, commit}, cls) {
            axios.get(rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/classes/${cls}?detail=pixel`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setCurrentClass', {cid:rootState.currentClient.cid, cls});
                commit('setCurrentClassData', response.data);  
            });
        },
        
        fetchDeploymentStatus({rootState, state, commit}) {
            axios.get(rootState.mgmtUrl + `/v1/clients/${rootState.currentClient.cid}/deployment/status`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setDeploymentStatus', response.data);             
                                
            });
        },
        fetchCountries({rootState, state, commit}) {
            axios.get(rootState.mgmtUrl + `/v1/countries`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setCountries', response.data.countries);  
            });
        },    
        fetchTagTemplates({rootState, state, commit}) {
            axios.get(rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/tag_templates`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setTagTemplates', response.data);  
            });
        },
        fetchPublicTagTemplates({rootState, state, commit}) {
            axios.get(rootState.surfaceUrl + `/v1/tag_templates`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setPublicTagTemplates', response.data);  
            });
        },   
        fetchInitiatorFields({rootState, state, commit},initiator) {
            initiator = (initiator!=undefined?"?initiator="+initiator:"")
            return axios.get(rootState.surfaceUrl + `/v1/initiator_fields`+initiator, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setInitiatorFields', response.data);
                return response;
            });
        },          
        async updateAutClientData({rootState}, payload) {
            return axios({
                method: 'put',
                url: rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}`,
                data: payload, 
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            }); 
        },
        async createAutClientClass({rootState}, payload) {
            return axios({
                method: 'post',
                url: rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/classes`,
                data: payload, 
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            }); 
        },
        async updateAutClientClass({rootState}, payload) {
            return axios({
                method: 'put',
                url: rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/classes/${payload.cls}`,
                data: payload.classObject, 
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            }); 
        },
        async deleteAutClientClass({rootState}, payload) {
            return axios({
                method: 'delete',
                url: rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/classes/${payload.cls}`,
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            }); 
        },
        async activateClientConfig({rootState}) {
            return axios({
                method: 'post',
                url: rootState.mgmtUrl + `/v1/clients/${rootState.currentClient.cid}/activate`,
                data: {}, 
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            }); 
        },

        async getDomainLists({rootState,commit}) {
            return axios.get(rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/domainlists`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setDomainList', response.data); 
                return response; 
            });
        },

        async getDomainListObject({rootState, commit},edgeHostName) {
            return axios.get(rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/domainlists/${edgeHostName}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setCurrentEdgeHostNameObj', response.data);  
                return response;
            });
        },

        async createDomainLists({rootState,commit}) {
            axios.post(rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/domainlists`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setDomainList', response.data);  
            });
        },

        async updateEdgeHostname({rootState}, payload) {
            return axios({
                method: 'put',
                url: rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/domainlists/${payload.edgeHostname}`,
                data: payload, 
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            }); 
        },

        async createTagTemplate({rootState}, tagTemplatePayload) {
            return axios.post(rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/tag_templates`, tagTemplatePayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readTagTemplate({rootState}, id) {
            return axios.get(rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/tag_templates/${id}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readPublicTagTemplate({rootState}, id) {
            return axios.get(rootState.surfaceUrl + `/v1/tag_templates/${id}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async updateTagTemplate({rootState}, tagTemplatePayload) {
            return axios.put(rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/tag_templates/${tagTemplatePayload.id}`, tagTemplatePayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteTagTemplate({rootState}, tagTemplatePayload) {
            return axios.delete(rootState.surfaceUrl + `/v1/cids/${rootState.currentClient.cid}/tag_templates/` + tagTemplatePayload.id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async fetchInitiatorField({rootState}, id) {
            return axios.get(rootState.surfaceUrl + `/v1/initiator_fields/${id}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async createInitiatorField({rootState}, initiatorPayload) {
            return axios.post(rootState.surfaceUrl + `/v1/initiator_fields`, initiatorPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readInitiatorField({rootState}, id) {
            return axios.get(rootState.surfaceUrl + `/v1/initiator_fields/${id}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async updateInitiatorField({rootState}, initiatorPayload) {
            return axios.put(rootState.surfaceUrl + `/v1/initiator_fields/${initiatorPayload.id}`, initiatorPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteInitiatorField({rootState}, initiatorPayload) {
            return axios.delete(rootState.surfaceUrl + `/v1/initiator_fields/` + initiatorPayload.id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async fetchInitiators({rootState, commit}) {
            return axios.get(rootState.surfaceUrl + `/v1/initiators`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setInitiators', response.data);
                return response;
            });
        },
        async createInitiator({rootState}, initiatorPayload) {
            return axios.post(rootState.surfaceUrl + `/v1/initiators`, initiatorPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readInitiator({rootState}, id) {
            return axios.get(rootState.surfaceUrl + `/v1/initiators/${id}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async updateInitiator({rootState}, initiatorPayload) {
            return axios.put(rootState.surfaceUrl + `/v1/initiators/${initiatorPayload.id}`, initiatorPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteInitiator({rootState}, initiatorPayload) {
            return axios.delete(rootState.surfaceUrl + `/v1/initiators/` + initiatorPayload.id, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        fetchPrivacySignals({rootState, commit}) {
            axios.get(rootState.mgmtUrl + `/v1/us_multi_state_privacy_signals`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setPrivacySignals', response.data);             
                                
            });
        },
        fetchPrivacyConfig({rootState, commit}, cid) {
            axios.get(rootState.mgmtUrl + `/v1/staging/clients/${cid}/us_multi_state_privacy_config`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setPrivacyConfig', response.data);             
                                
            });
        },
        async addPrivacyConfig({rootState}, payload) {
            return axios.post(rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/us_multi_state_privacy_config`, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async updatePrivacyConfig({rootState}, payload) {
            return axios.put(rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/us_multi_state_privacy_config/${payload.name}`, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deletePrivacyConfig({rootState}, name) {
            return axios.delete(rootState.mgmtUrl + `/v1/staging/clients/${rootState.currentClient.cid}/us_multi_state_privacy_config/${name}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
    }

    
}

export default store