<template>
    <div class="modal-card card">
        <form @submit.prevent="onEdgeHostName()">  
        <div class="card-header">
            <!-- Title -->
            <h4 v-if="!modalData" class="card-header-title">
                Add EdgeHostName
            </h4>
            <h4 v-else class="card-header-title">
                Add Domain Record
            </h4>
            <!-- Close -->
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>
        <div class="card-body">
                <form-fields :fields="fields" v-model="domainList" />
                <div v-if="error" class="text-danger">
                    {{error}}
                </div>
        </div>
        <div class="card-footer">
            <div class="text-end">
                <submit-button :fields="fields" v-model="domainList"  :loading="loading"/>
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import formFields from '../FormFields.vue';
import submitButton from '../SubmitButton.vue'
import {mapActions} from 'vuex'
import Fields from "../../utils/field-constants/domainListFields"
export default {
    components: { formFields, submitButton },
    data(){
        return {
            error: "",
            domainList: {},
            fields: Fields,
            loading: false
        }
    },
    props: {       
        modalData: {
            type: [Object, Array, String],
        }
    },
    beforeMount() {  
        let self = this;     
        this.fields =  this.fields.map((field) => {
            if((field.name == "edgeHostname" | field.name=="fipsEnabled")&& self.modalData) {  //Visiblity false for edgehostname and fipsEnabled when adding domain record
                field.visible = false;                                            
                return field;                      
            } else {
                field.visible = true;
            }
            return field;
        });         
    },
    methods: {
        ...mapActions('aut', {
            updateEdgeHostname: 'updateEdgeHostname'
        }),
        async onEdgeHostName() {            
            let domainListObj = {};
            let domainArr = [];
            let ordinal = 0;
            if(this.modalData && this.modalData.domains && this.modalData.domains.items) {
                this.modalData.domains.items.map((domainObj) => {
                    if(ordinal < domainObj.ordinal)
                        ordinal = domainObj.ordinal;               
                });
                ordinal++;
            }
            
            if(this.domainList.domains && this.domainList.domains.length) {
                domainArr = this.domainList.domains.map((domainName) => {
                    let domainObj = {};
                    domainObj.name = domainName;
                    domainObj.ordinal = ordinal;
                    domainObj.status= "PENDING";
                    domainObj.recordType = this.domainList.recordType;
                    ordinal++;
                    return domainObj;
                });
            }

            if(this.modalData) {
                domainListObj = this.modalData
                domainListObj.domains = this.modalData.domains;
                domainListObj.domains.items = [...this.modalData.domains.items, ...domainArr];
            } else {
                domainListObj.edgeHostname = this.domainList.edgeHostname;
                domainListObj.fipsEnabled =this.domainList.fipsEnabled
                domainListObj.domains = {"items": domainArr};
            }               
            this.onUpdateEdgeHostname(domainListObj); 
           
        },

        async onUpdateEdgeHostname(domainListObj) {
            let toastOptions;
            this.loading = true;
             try {
                await this.updateEdgeHostname(domainListObj);
                toastOptions = {
                    message: "Updated Edge Hostname successfully!",
                    type: "success",
                };
            } catch (error) {
                if (error.response.data.error) 
                    this.error = error.response.data.error
                else 
                    this.error = error.message

                toastOptions = {
                    message: this.error || "Something went wrong when updating hostname. Please contact support.",
                    type: "error",
                };
                this.$store.dispatch("addToast", toastOptions);                
                return
            }  
            this.loading = false;          
            this.$store.dispatch("addToast", toastOptions);
            this.$store.dispatch('hideModal');
            if(toastOptions.type == "success") {
                if(this.modalData) 
                    this.$store.dispatch("aut/getDomainListObject", this.modalData.edgeHostname);
                else
                    this.$router.push({ name: 'Configure CNames', params: {hostName: this.domainList.edgeHostname}})  
            }
           

        }
    }
}
</script>

<style>

</style>