<template>
    <DetailHeader pretitle="Credentials" :title="credential ? credential.name : '&nbsp;'" :editable="true" :editRoles="allowedUserRoles"
        :editMode="editMode" @editClick="onEditClick" @deleteClick="onDeleteClick" 
        :fields="fields" v-model="credValue" @saveClick="onSaveClick"
        @cancelClick="onCancelClick" @closeClick="$router.push(`/${$store.state.currentClient.cid}/amdp/credentials`)" />
    <div class="container-fluid">
        <div v-if="error" class="alert alert-danger" role="alert">
            {{ error }}
        </div>
        <div v-if="successMessage" class="alert alert-success" role="alert">
            {{ successMessage }}
        </div>
        <div v-if="credential">
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group">
                                <label class="form-label">Credential Type <span
                                        class="text-danger">&nbsp;*</span></label>
                                <select class="form-select" @change="onSelectCredentialType($event)"
                                    :disabled="!editMode" :readonly="!editMode" v-model="credType">
                                    <option v-for="option in this.credentialOptions" :key="option.index"
                                        :value="option.value">
                                        {{ option.label }}
                                    </option>
                                </select>
                            </div>
                            <FormFields :fields="fields" v-model="credValue" :editMode="editMode" />
                            <div v-if="error" class="text-danger">
                                {{ error }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
import DetailHeader from "../../components/DetailHeader.vue";
import FormFields from "../../components/FormFields.vue";
import { mapActions } from "vuex";
import { isDuplicate } from "../../utils/commonFunction";
export default {
    name: "Credential",
    components: { DetailHeader, FormFields },
    data() {
        return {
            editMode: false,
            credential: {},
            snapshot: null,
            error: "",
            successMessage: "",
            credentialOptions: [],
            credName: "",
            credType: "",
            credComputed: {},
            referenceCredName: "",
            allowedUserRoles:[1,3]
        };
    },
    beforeMount() {          
        this.fetchCredential();
        this.fetchCredentialTypes().then(() => {
            this.credentialOptions = this.credentialTypes.map((type) => {
                return {
                    label: type.name,
                    value: type.name,
                }
            })
        });
       
    },
    computed: {
        fields() {
            //calcualting Dynamic fields based on Credential Type
            let fields = [{
                name: "name",
                type: "text",
                label: "Credential Name",
                placeholder: "Enter name",
                validation: [{ type: "required" },
                {
                    type: "regex", pattern: "^[a-zA-Z0-9_]+$",
                    errMsg: "Credential name may contain only alphabets or numbers"
                }]
            }]
            if (this.credential.fields) {
                this.credential.fields.map((value, index) => {
                    fields.push({
                        name: value.name,
                        type: "text",
                        label: value.name,
                        editable: true,
                        url: value.type == "url" ? "https://onetimesecret.com/" : null,
                        placeholder: value.description,
                        toolTip: value.type == "url" ? "Do not directly enter your secret here. use the onetimesecret url generated using the 'key'" : null,
                        validation: value.required ? [{ type: "required" }] : []
                    });
                    //If field type is url then it should contain onetimesecret url
                    if (value.type == 'url') {
                        fields[index + 1].validation.push({
                            type: "url", base: "https://onetimesecret.com/secret/",
                            errMsg: "Onetimesecret URL is required"
                        })
                    }

                })
            }
            return fields
        },
        //setting v-model value for form-fields
        credValue: {
            get() {
                return this.credComputed
            },
            set(newval) {
                this.credComputed = newval
            }
        },
        credentialTypes(){
            return this.$store.state.amdp.credentialTypes;
        }

    },
    methods: {
        ...mapActions({
            fetchCredentials: "amdp/fetchCredentials",
            fetchCredentialTypes: "amdp/fetchCredentialTypes",
            readCredential: "amdp/readCredential",
            updateCredential: "amdp/updateCredential",
            deleteCredential: "amdp/deleteCredential",
            showModal: "showModal",
        }),
        setEditMode(mode) {
            this.editMode = mode;
            this.successMessage = null;
            this.error = null;
        },
        async fetchCredential() {
            let response={};
            if(!this.$route.params.data){
                try {
                    response = await this.readCredential(this.$route.params.id);
                } catch (error) {
                    if (error.response.data)
                        this.error = error.response.data.error;
                    else this.error = error.message;
                    return;
                }
            }else{
                response["data"] = JSON.parse(this.$route.params.data)
            }
            this.credential = await response.data;
            //Setting credential name and type
            this.credName = this.referenceCredName = this.credential.name;
            this.credType = this.credential.type;
            this.snapshot = { ...this.credential };
            this.findCredentialValue();

        },
        onSelectCredentialType(event) {
            if (this.credType !== this.snapshot.type) {
                this.credential = this.credentialTypes.find(type => {
                    return type.name == event.target.value
                });
                this.credential.type= this.credential.name;
                this.credential.name=this.credName;
            } else {
                this.credential = this.snapshot;
            }
            this.findCredentialValue();
        },
        findCredentialValue() {
            this.setCredentialFields();
            //Finding default credential value for v-model form-field
            let cred = {
                name: this.credName,
                type: this.credType,
            }
            if (this.credential.fields) {
                this.credential.fields.map((field) => {
                    if (field.type == "url" && !this.editMode) {
                        cred[field.name] = field.value && field.value !== "" ? "***********" : ""
                    } else if(field.type == "url" && this.editMode) {
                        //it is onetimesecret url can be used once, reenter again while editing
                        cred[field.name] = ""
                    }else{
                        cred[field.name] = field.value
                    }
                })
            }
            this.error = null
            this.credComputed = cred
        },
        onCancelClick() {
            this.setEditMode(false);
            this.credential = { ...this.snapshot };
            this.credName = this.credential.name;
            this.credType = this.credential.type
            this.error = null;
            this.findCredentialValue()
        },
        onEditClick() {
            this.setEditMode(true);
            this.findCredentialValue()
        },
        onDeleteClick() {
            const self = this;
            this.showModal({
                component: "confirm",
                data: {
                    title: "Delete Credential",
                    message: `Are you sure you want to delete ${this.credName}?`,
                    async confirm() {
                        try {
                            const response = await self.deleteCredential(self.credName);
                            self.$router.push(`/${self.$store.state.currentClient.cid}/amdp/credentials`);
                            return response;
                        } catch (error) {
                            if (error.response)
                                self.error = error.response.data.message;
                            else self.error = error.message;
                            return;
                        }
                    },
                },
            });
        },
        async onSaveClick() {
            this.error=null;
            //Fetch all credentials to check duplicate name
            if(Object.keys(this.$store.state.amdp.credentials).length ==0)
                  await  this.fetchCredentials()
            if (!isDuplicate(this.credValue.name, this.$store.state.amdp.credentials, "name",this.referenceCredName)) {
                try {
                    let credentialPayload = {
                        name: this.credValue.name,
                        type: this.credType,
                        fields: this.credential.fields.map((field) => {
                            field["value"] = this.credValue[field.name]
                            return field
                        })
                    };
                    const response = await this.updateCredential({ credentialPayload: credentialPayload, referenceCredName: this.referenceCredName });
                    this.setEditMode(false);
                    this.snapshot = { ...this.credential };
                    this.successMessage = "Credential updated successfully.";
                    this.$router.push(`/${this.$store.state.currentClient.cid}/amdp/credentials`);
                } catch (error) {
                    if (error.response)
                        this.error = error.response.data.message;
                    else this.error = error.message;
                }
            } else {
                this.error = "'" + this.credValue.name + "' name exits."
            }
        },
        setCredentialFields(){
            if(this.credential.fields &&  this.credentialTypes){
                let allCredFields = this.credentialTypes.find(type => {
                    return type.name == this.credType
                });
                const result= allCredFields.fields.filter(a_field =>
                    !this.credential.fields.some(credfield => credfield.name == a_field.name)
                );
                this.credential.fields.push(...result)
            }
        }
    }
};
</script>

<style>

</style>