import axios from 'axios'

const store = {
    namespaced: true,
    state: {
        sandboxes: [],
        devSandboxes:[],
        prodSandboxes:[],
        prodV2Sandboxes:[],
        sandboxJobs:[],
        currentSandbox: null,
        executionsListLimit: 100,
        promotionsListLimit: 100,
        testHistoryListLimit: 100,
        jobFileListDefinitions: {
            "MAPPER" : {"system_files":[
                {content:"", relative_path:"data_channels.yaml"},
                {content:"", relative_path:"jobconf.yaml"},
                {content:"", relative_path:"mappings.yaml"},
            ]},
            "DEFAULT": {"system_files":[
                {content:"", relative_path:"config.yaml"},
            ]},
            "AIO-HANDLER":{"system_files": [
                {content:"name: my_handler\nparameters:\n  first_config: one\n  second_config: two", relative_path:"config.yaml"}                
            ],
            "user_files":[{content:"package main\n\nfunc (h *MyHandler) AfterGetEntity(data *HandlerData) (*HandlerData, error) {\n    data.Entity.Attributes[\"attributeName\"] = \"attributeValue\"\n    return data, nil\n}\n", relative_path:"handler.go"}
            ]},
            "AIO-HANDLER-DBDS":{"system_files": [
                {content:"name: my_handler\nparameters:\n  first_config: one\n  second_config: two", relative_path:"config.yaml"}],
            "user_files":[{content:"package main\n\nfunc (h *MyHandler) AfterGetEntity(data *HandlerData) (*HandlerData, error) {\n    data.Entity.Attributes[\"attributeName\"] = \"attributeValue\"\n    return data, nil\n}\n", relative_path:"handler.go"}
            ]},
            "AIO-HANDLER-GRAPHDS":{"system_files": [
                {content:"name: my_handler\nparameters:\n  first_config: one\n  second_config: two", relative_path:"config.yaml"}],
            "user_files":[{content:"package main\n\nfunc (h *MyHandler) AfterGetEntity(data *HandlerData) (*HandlerData, error) {\n    data.Entity.Attributes[\"attributeName\"] = \"attributeValue\"\n    return data, nil\n}\n", relative_path:"handler.go"}
            ]},
            "AGM": {"system_files": [
                {content:"inputs:\n  - name: aqfer_delta\n    locations: *input_location\n    verification:\n      error_on_failure: true\n      error_file_list_path: *error_path\n      schema_files:\n        - '{{env \"USER_DIR\"}}/schemas.yaml'\n      schema_name: aqfer_delta\ntransform:\n  - python:\n      # timeout_in_sec: 50\n      file: '{{env \"USER_DIR\"}}/transform.py'\noutputs:\n  - name: avro_file\n    destination: *output\n    split_by:\n      key:\n        - tenant_id\n        - entity_type\n        - entity_domain\n        - entity_neighbor_type\n        - entity_neighbor_domain\n        - batch_id\n      strip_key: true\n      ",
                 relative_path:"config.yaml" },
                {content:"locations:\n  &input_location\n    - folder: s3:{{input-file}}\noutput: &output s3://{{output-file}}/\nerror_path: &error_path 's3:{{error-path}}/'\n", relative_path:"vars.yaml"}   
                             
            ],
            "user_files":[{content:"", relative_path:"edge_extract.yaml"}
            ]},
        },
        sandboxJobRuns: [],
        jobRunParams: {},
        sandboxPromotions: [],
        promotionExecutions: [],
        promotionConfig: [],
        latestSuccessfulJobRun: null,
        successfulJobRunList: [],        
        pipelines:[],
        jobExecutionProgress:null,
        executorLogs:{},
        sandboxExcludeList: {"amdp":["AIO-HANDLER","AIO-HANDLER-DBDS","AIO-HANDLER-GRAPHDS","AQFS"]}

    },
    mutations: {
        setSandboxes(state, sandboxes) {
            state.sandboxes = sandboxes;
            state.devSandboxes = sandboxes.filter((e) => { return e.env == "DEV" });
            state.prodSandboxes = sandboxes.filter((e) => { return e.env == "PROD" });
            state.prodV2Sandboxes = sandboxes.filter((e) => { return e.env == "PROD_V2" });                      
        },
        setSandboxJobs(state, sandboxJobs) {
            state.sandboxJobs = sandboxJobs;
        },
        setCurrentSandbox(state, sandbox) {
            state.currentSandbox = sandbox;
        }, 
        setExecutionsListLimit(state, limit) {
            state.executionsListLimit = limit;
        }, 
        setJobTag(state, {jobName,tag}) {
            state.jobRunParams[jobName].tag = tag; 
        },
        setJobImage(state, {jobName,image}) {
            state.jobRunParams[jobName].image = image;
        },
        setJobEnvParams(state, {jobName,envParams}) {
            state.jobRunParams[jobName].envParams = envParams;
        },
        setDefaultJobRunParams(state, jobName) {
            if(!state.jobRunParams[jobName])
                state.jobRunParams[jobName] = {
                    tag: null,
                    image: null,
                    envParams: []
                };
        },
        setSandboxPromotions(state, promotions) {
            state.sandboxPromotions = promotions
        },
        setPromotionExecutions(state, promotionExecutions) {
            state.promotionExecutions = promotionExecutions;
        },
        setPromotionConfig(state, promotionConfig) {
            state.promotionConfig = promotionConfig;
        },
        setSandboxJobRuns(state, jobRuns) {
            state.sandboxJobRuns = jobRuns;
        },
        setSuccessFulJobRunList(state, jobRunList) {
            state.successfulJobRunList = jobRunList;
        },
        setLatestSuccessFulJobRun(state, jobRun) {
            state.latestSuccessfulJobRun = jobRun;
        },
        setPipelines(state, pipelines) {
            state.pipelines = pipelines;
        },
        setJobExecutionProgress(state, progress) {
            state.jobExecutionProgress = progress;
        },
        setExecutorLogs(state, {executorId,executorLogObj}) {
            state.executorLogs[executorId] = executorLogObj;
        },
    },
    actions: {
        //Sandbox actions
        setCurrentSandbox({ commit }, sandbox) {
            commit('setCurrentSandbox', sandbox)
        },
        setSandboxJobRuns({commit}, runs) {
            commit('setSandboxJobRuns', runs)
        },
        setExecutionsListLimit({ commit }, limit) {
            commit('setExecutionsListLimit', limit)
        },
        onClientChange({commit}) {
            commit('setSandboxJobs', []);
            commit('setSandboxes', []);
        },
        async afterClientChange() {
            await this.dispatch('sandbox/fetchSandboxes');
        },
        setJobTag({commit}, tagObj) {
            commit('setJobTag', tagObj);
        },
        setJobImage({commit}, imageObj) {
            commit('setJobImage', imageObj);
        },
        setJobEnvParams({commit}, envParamsObj) {
            commit('setJobImage', envParamsObj );
        }, 
        setDefaultJobRunParams({commit}, jobName) {
            commit('setDefaultJobRunParams', jobName );
        },
        setExecutorLogs({commit}, logObj) {
            commit('setExecutorLogs', logObj);
        },       
        fetchSandboxes({rootState, state, commit}, cid) {
            let currentCid = cid || rootState.currentClient.cid;
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${currentCid}/sandboxes`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setSandboxes', response.data);
                return response.data;
            }).catch((err)=> {
                commit('setSandboxes', []);
                return err;
            });
        },

        async createSandbox({rootState, state}, sandboxPayload) {
            return axios.post(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes`, sandboxPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async readSandbox({rootState}, sandboxName) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/` + sandboxName, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async updateSandbox({rootState}, sandboxPayload) {
            return axios.put(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/` + sandboxPayload.name, sandboxPayload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteSandbox({rootState}, sandboxName) {
            return axios.delete(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${sandboxName}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        //Sandbox user actions
        async fetchSandboxUsers({rootState}, sandboxName) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${sandboxName}/users`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async addSandboxUser({rootState}, params) {
            return axios({
                method: 'put',
                url: rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${params.sandboxName}/users/${params.email}`,
                data: params.payload,
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            })           
        },        
        async deleteSandboxUser({rootState}, params) {
            return axios({
                method: 'delete',
                url: rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${params.sandboxName}/users/${params.email}`,
                headers: { 'Authorization': 'Bearer ' + rootState.accessToken }
            });           
        },

        async fetchSandboxJobUsers({rootState}, sandboxName) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${sandboxName}/jobs/aqfs/users`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },

        // Sandbox job actions
        fetchSandboxJobs({rootState, state, commit}) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/jobs`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {                
                commit('setSandboxJobs', response.data || []);
            }).catch((err)=> {
                commit('setSandboxJobs', []);
            });
        },

        async readSandboxJob({rootState, state}, jobName) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/jobs/${jobName}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },

        async readOverrideFile({rootState, state}, jobName) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/jobs/${jobName}/override`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })

        },

        async createSandboxJob({rootState, state}, payload) {
            return axios.post(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/jobs`, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async updateSandboxJob({rootState, state}, payload) {
            return axios.put(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/jobs/${payload.name}`, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async updateSandboxJobMetadata({rootState, state}, {payload, jobName}) {
            return axios.put(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/jobs/${jobName}/metadata`, payload, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async deleteSandboxJob({rootState, state}, jobName) {
            return axios.delete(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/jobs/${jobName}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },

        async fetchSandboxJobRuns({rootState, state, commit}, {jobName, limit}) {
            let self = this;
            let urlTemplate = rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/jobs/${jobName}/executions`
            if(limit)
                urlTemplate = `${urlTemplate}?limit=${limit}`
            return axios.get(urlTemplate, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response) => {
                commit('setSandboxJobRuns', response.data || [] );
                self.dispatch('sandbox/getLatestSuccessJobRun', response.data);
                return response;
            }).catch((e) => {
                commit('setSandboxJobRuns', [] );
                return e;
            })
        },

        getLatestSuccessJobRun({state, commit}, jobRunList) {
            if(jobRunList.length) {        
                let filteredData = jobRunList.filter(item => {
                    return item.status === 'SUCCEEDED';
                });                
                filteredData = filteredData.sort((a, b) =>  new Date(b.completed_at)- new Date(a.completed_at));
                if(filteredData.length) {
                    commit('setSuccessFulJobRunList', filteredData);  
                    const latest = filteredData.reduce((acc, curr) => {
                        return new Date(curr.completed_at) > new Date(acc.completed_at) ? curr : acc;
                    });
                    latest.id?commit('setLatestSuccessFulJobRun', latest):commit('setLatestSuccessFulJobRun', null); 
                    return;     
                }
                commit('setSuccessFulJobRunList', []);                                                         
            } 
            commit('setLatestSuccessFulJobRun', null);  
        },

        async cancelSandboxJobExecution({rootState, state}, {jobName, executionId}) {
            return axios.delete(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/jobs/${jobName}/executions/${executionId}`,{
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                }, 
            })
        },
        async fetchSandboxPromotions({rootState, state, commit},limit) {
            limit = limit?limit:state.promotionsListLimit;
            return axios.get(rootState.lakeviewUrl+`/v1/cids/${rootState.currentClient.cid}/sandboxes/${state.currentSandbox}/promotions?limit=${limit}`,{
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`
                }
            }).then((response) => {
                commit('setSandboxPromotions', response.data);
                return response.data;
            }).catch((err)=> {
                commit('setSandboxPromotions', []);
                return err;
            }); 
        },

        async acceptPromotion({rootState, state}, {promotionId, sandboxId}) {
            return axios.post(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${sandboxId}/promotions/${promotionId}/accept`, {}, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async completePromotion({rootState, state}, {promotionId, sandboxId}) {
            return axios.post(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${sandboxId}/promotions/${promotionId}/complete`, {}, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        async cancelPromotion({rootState, state}, {promotionId, sandboxId}) {
            return axios.post(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${sandboxId}/promotions/${promotionId}/cancel`, {}, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            })
        },
        
        async fetchPromotionExecutions({rootState, state, commit},{promotionId, limit}) {
            limit = limit?limit:state.executionsListLimit;
            return axios.get(rootState.lakeviewUrl+`/v1/cids/${rootState.currentClient.cid}/promotions/${promotionId}/executions?limit=${limit}`,{
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`
                }
            }).then((response) => {
                commit('setPromotionExecutions', response.data);               
            }).catch((err)=> {
                commit('setPromotionExecutions', []);
            }); 
        },

        async fetchPromotionConfig({rootState, state, commit},{promotionId}) {
            return axios.get(rootState.lakeviewUrl+`/v1/cids/${rootState.currentClient.cid}/promotions/${promotionId}/configs`,{
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`
                }
            }).then((response) => {
                commit('setPromotionConfig', response.data); 
                return response.data;              
            }).catch((err)=> {
                commit('setPromotiionConfig', []);
                return err;
            }); 
        },
        async fetchSandboxLog({rootState},{payload}){
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${payload.sandboxName}/jobs/${payload.jobName}/executions/${payload.executionId}/logs`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`
                }
            })
        },
        async fetchPipelines({rootState, commit}, sandboxName) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${sandboxName}/pipelines-v0`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`,
                },
            }).then((response)=>{
                commit('setPipelines',response.data.pipelines);
                return response;
            })  
        }, 
        
        async fetchPipeline({rootState}, param) {
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${param.sandboxName}/pipelines-v0/${param.pipelineName}`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`
                },
            }).then((response)=>{
                return response.data;
            })  
        },
        
        async getJobExecutionProgress({rootState, commit}, param){
            return axios.get(rootState.lakeviewUrl + `/v1/cids/${rootState.currentClient.cid}/sandboxes/${param.sandboxName}/jobs/${param.jobName}/executions/${param.executionId}/progress`, {
                headers: {
                    Authorization: `Bearer ${rootState.accessToken}`
                },
            }).then((response)=>{
                commit("setJobExecutionProgress", response.data);
                return response.data;
            }).catch((err)=> {
                commit('setJobExecutionProgress', null);
                throw err;
            });   
        }
        
        
        

                
    },
    getters: {
        hasProdV2Sandbox(state) {
            return state.prodV2Sandboxes && state.prodV2Sandboxes.length > 0
        },
        computeNavRoute:(state, getters) => (item) => {
            let route = item.route;
            let sandboxArray =  getters.getSandboxArrByType(item.sandboxTypes);
            let currentSandbox = state.currentSandbox;
            

            if(sandboxArray.filter((e) => { return e.name === currentSandbox; }).length == 0 && sandboxArray.length){
                currentSandbox = sandboxArray[0].name
            }

            route = route.replace(/:sandboxId/g, currentSandbox);
            return route;
        },
        initializeCurrentSandbox:(state, getters) => (route) => {
            let initializeSandbox = null;
            let sandboxArray =  getters.getSandboxArrByType(route.meta.sandboxTypes);
            
            if(sandboxArray.length) {
                initializeSandbox = sandboxArray[0].name
            }
            return initializeSandbox;

        },
        getSandboxArrByType: (state) => (typeArr) => {
            typeArr = typeArr? typeArr: [];
            let sandboxArray = [];
            let filteredSandboxArray = [];
            if(!state.sandboxes || !state.sandboxes.length)
                return sandboxArray;


            for (const type of  typeArr) {
                let sandboxTypeArray = [];
                switch(type) {
                    case 'dev': 
                        filteredSandboxArray = (state.devSandboxes && state.devSandboxes.length)?state.devSandboxes.filter((e) => {
                            return e.name.match(/(c\d\d\d-dev)$/g);                    
                        }):[];              
                        sandboxTypeArray = filteredSandboxArray.length?filteredSandboxArray:state.devSandboxes;
                        break;
                    case 'prod':
                        filteredSandboxArray = (state.prodSandboxes && state.prodSandboxes.length)?state.prodSandboxes.filter(e => e.name=="default"):[];
                        sandboxTypeArray = filteredSandboxArray.length?filteredSandboxArray:state.prodSandboxes;
                        break;
                    case 'prodV2':
                        filteredSandboxArray = (state.prodV2Sandboxes && state.prodV2Sandboxes.length)?state.prodV2Sandboxes.filter(e => e.name=="default"):[];
                        sandboxTypeArray = filteredSandboxArray.length?filteredSandboxArray:state.prodV2Sandboxes;
                        break;
                    default:
                        sandboxTypeArray = state.sandboxes;
                        break;
                }
                sandboxArray = sandboxArray.concat(sandboxTypeArray)
            }            
            return sandboxArray;

        }
        

    }
}

export default store;