const Fields = [
    {
        name: "edgeHostname",
        label: "Edge Hostname",
        type: "text",
        editable: true,
        toolTip: "Name of edge-hostname to add",
        placeholder: "ex:a.emaple.com",
        validation: [
            { type: "required", errMsg: "Name is required" }
        ],
        visible: true,
    },
    {
        name: "domains",
        label: "Domain record",
        type: "array",
        toolTip: "Comma seperated list of domain record names to add",
        placeholder: "ex: aaa.example.com,bbb.example.com",
        editable: true,
        validation: [
            { type: "required" }
        ],
        visible: true,
    },
    {
        name: "fipsEnabled",
        label: "Enable FIPS",
        toolTip: "Use FIPS compliant certificate for domains",
        type: "checkbox",
        editable: true,
        visible: true,
    },
    {
        name: "recordType",
        label: "Type",
        toolTip: "Type of Domain Record",
        type: "select",
        editable: true,
        validation: [
            { type: "required" }
        ],
        options:[
            { label: "CNAME", value: "CNAME" },
            { label: "Alias", value: "A" },
            { label: "Proxy", value: "PROXY" },
            { label: "FWD", value: "FWD" }
        ],
        visible: true,
    }
];

export default Fields;