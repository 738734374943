<template>
	<div>         
        <div class="card">  
            <Datatable
                tableId="meterTable"
                :columns="columns"
                :data="meters"
                :showLoading="meters===null" 
            />
    </div>
	</div>
</template>
<script>
import Datatable from '../../../components/Datatable.vue';
	export default {
		components: { Datatable },
		data() {
			return {
                columns:[
                { data: "label"},
                { data: "metrics_type", title: "metrics type"},
                { data: "metrics_field", title: "metrics field"},
                { data: "metrics_data_channel",title: "metrics data channel"}
            ]
			};
		},
		props: ["meters"]
	};
</script>
<style>
</style>
